<template>
  <v-footer class="primary">
    <v-container class="pa-0 pa-sm-auto">
      <v-row no-gutters v-if="links" justify="space-between" class="py-4">
        <v-col
          cols="12"
          md="2"
          class="d-flex flex-row flex-md-column justify-space-between justify-sm-space-around justify-md-center justify-md-start align-center align-sm-start mb-6 mb-md-0"
        >
          <!-- Logo -->
          <router-link :to="{ name: 'Home' }">
            <v-img
              src="/insegne/poli_white.svg"
              class="my-2 mx-auto"
              contain
              height="40"
              max-width="110"
              alt="logo Poli"
            />
          </router-link>
          <router-link :to="{ name: 'Home' }">
            <v-img
              src="/insegne/orvea_white.svg"
              class="my-2 mx-auto"
              contain
              height="40"
              max-width="110"
              alt="logo Orvea"
            />
          </router-link>
          <router-link :to="{ name: 'Home' }">
            <v-img
              src="/insegne/amort_white.svg"
              class="my-2 mx-auto"
              contain
              height="40"
              max-width="110"
              alt="logo Amort"
            />
          </router-link>
        </v-col>
        <!-- custom layout for column with "socials" CSS class, showing button for app download -->
        <!-- "socials" column not rendered on mobile app -->
        <v-col
          v-for="(link, index) in links"
          :key="index"
          :cols="isSocialLinksColumn(link.classes) ? 12 : 6"
          sm="4"
          :md="isSocialLinksColumn(link.classes) ? 3 : 2"
          class="flex-column justify-start align-center align-md-start"
          :class="isCordovaPlatform(link.classes) ? 'd-none' : 'd-flex'"
        >
          <h4
            :class="
              isSocialLinksColumn(link.classes)
                ? 'mt-4 mt-sm-0 white--text text-uppercase text-body-2 font-weight-bold text-center text-sm-left'
                : 'white--text text-uppercase text-center text-body-2 font-weight-bold text-sm-left'
            "
          >
            {{ link.title }}
          </h4>
          <div class="d-flex flex-column align-center align-md-start">
            <a
              v-for="child in link.children"
              :key="child.ID"
              :href="child.url"
              class="white--text text-decoration-none my-1"
              :class="[child.classes, { 'no-evt': index == 2 }]"
              :target="child.target"
              @click="clickLink"
            >
              <v-img
                v-if="child.classes == 'appstore-bg'"
                src="/img_layout/footer/app-store.png"
                contain
                :max-height="$vuetify.breakpoint.sm ? 35 : 45"
                alt="logo App Store"
              />
              <v-img
                v-else-if="child.classes == 'gplay-bg'"
                src="/img_layout/footer/google-play.png"
                contain
                :max-height="$vuetify.breakpoint.sm ? 35 : 45"
                alt="logo Google Play"
              />
              <span :class="child.classes" v-else>{{ child.title }}</span>
            </a>
          </div>
        </v-col>
      </v-row>
      <v-divider class="white" />
      <div class="mt-4 mb-12 d-flex flex-row align-center justify-center">
        <span class="copyright text-center white--text text-caption">
          {{ $t("footer.copyright") }}
        </span>
        <i18n path="footer.version" tag="span" class="white--text text-caption">
          {{ version }}
        </i18n>
      </div>
    </v-container>
  </v-footer>
</template>
<style scoped lang="scss">
a {
  font-size: $font-size-root;
}
.credits {
  font-size: 12px;
  a {
    text-decoration: none !important;
  }
}
</style>
<script>
import clickHandler from "@/commons/mixins/clickHandler";
export default {
  name: "Footer",
  mixins: [clickHandler],
  props: {
    links: { type: Array, required: true }
  },
  computed: {
    version() {
      return global.config.version;
    },
    isMobile() {
      return true;
    }
  },
  methods: {
    isSocialLinksColumn(classes) {
      return classes.search("social") > -1;
    },
    isCordovaPlatform(classes) {
      return this.isSocialLinksColumn(classes) && this.cordovaPlatform;
    },
    isCreditsClass(classes) {
      return classes.search("classes") > -1;
    },
    clickLink: function(event) {
      if (event.target.className.includes("iubenda-cs-preferences-link", 0)) {
        // eslint-disable-next-line no-undef
        _iub.cs.api.openPreferences();
      }
    }
  }
};
</script>
